#header {
    padding: 12px 25px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    transition: all .25s ease;
    z-index: 99;

    @include media-breakpoint-up(md) {
        padding: 15px 35px;
    }

    @include media-breakpoint-up(lg) {
        padding: 18px 40px;
    }

    @include media-breakpoint-up(xl) {
        padding: 22px 40px;
    }

    &.menu-open {

        @include media-breakpoint-down(md) {
            background-color: #fff;
            box-shadow: $shadow;
        }
    }

    .navbar {
        padding: 0;
    }

    .navbar-brand {
        padding: 0;
    }


    .navbar-nav {
        font-size: 16px;
        line-height: 1.357142857142857;
        padding: 15px 0;
        @extend %heading;

        @include media-breakpoint-up(lg) {
            padding: 0;
            font-size: 12px;
        }

        li {
            margin: 8px 0;

            &:not(:last-child) {

                @include media-breakpoint-up(md) {
                    margin-right: 36px;
                }
            }

            a {
                color: inherit;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

$menu-trigger-width: 30px;
$menu-trigger-height: 24px;
$menu-trigger-color: $primary;

.navbar-toggler {
    @include size($menu-trigger-width,$menu-trigger-height);
    transition: all .25s;
    cursor: pointer;
    z-index: 10001;
    display: block;
    margin-left: 20px;
    position: relative;
    border: none;
    padding: 0;
    outline: none !important;

    @include media-breakpoint-up(md) {
        width: 24px;
        height: 16px;
    }

    span {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        transform: translate(0 -50%);
        background-color: $menu-trigger-color;
        font-size: 0px;
        user-select: none;
        transition: all .25s ease;

        &:before, &:after {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background: $menu-trigger-color;
            content: '';
            transition: all .25s ease;
        }

        &:before {
            transform: translate(0, -300%) rotate(0deg);
        }

        &:after {
            transform: translate(0, 300%) rotate(0deg);
        }
    }

    &:not(.collapsed) {

        span {
            background-color: transparent !important;

            &:before {
                transform: translate(0) rotate(45deg);
            }

            &:after {
                transform: translate(0) rotate(-45deg);
            }
        }
    }
}
