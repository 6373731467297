html {
    height: 100%;
    font-size: 13px;

    @include media-breakpoint-up(md) {
        font-size: 14px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 15px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 16px;
    }
}

body {
    height: 100%;
    position: relative;
    min-width: 320px;
}

.layout {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

.font-weight-medium {
    font-weight: 500;
}

.container {
    @include make-container(1168px);
}

.container-xs {
    @include make-container(570px);
}

.container-fluid {
    @extend %container;
}

.sep {
    @extend .container;
    margin-top: 0;
    margin-bottom: 0;
    border: none;

    &:before {
        display: block;
        content: '';
        border-top: 1px solid #ecfbef;
    }
}

.container-xs {
    max-width: 720px + $grid-gutter-width;
}

.container-sm {
    max-width: 920px + $grid-gutter-width;
}

%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%text-default {
    font-size: 1rem;
    line-height: 1.5;
}

.text-default {
    @extend %text-default;
}


%text-big {
    font-size: 1.125em;
    line-height: 1.125;
}

.text-big {
    @extend %text-big;
}

%text-small {
    font-size: .875em;
}

.text-small {
    @extend %text-small;
}

%text-lead {
    font-size: 16px;
    line-height: (30/22);

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 20px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 22px;
    }
}

.text-lead {
    @extend %text-lead;
}

%heading {
    font-weight: 700;
    font-family: $font-heading;
    color: $heading-color;
}

%section {
    padding: 38px 0;

    @include media-breakpoint-up(sm) {
        padding: 48px 0;
    }

    @include media-breakpoint-up(md) {
        padding: 58px 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 68px 0;
    }

    @include media-breakpoint-up(xl) {
        padding: 98px 0;
    }
}

section,
.section {
    @extend %section;
}

.section-head {
    text-align: center;
    margin-bottom: 37px;

    @include media-breakpoint-up(md) {
        margin-bottom: 47px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 57px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 67px;
    }
}

.section-title {
    margin-bottom: 15px;
}

.section-subtitle {
    font-size: 1em;
    line-height: 1.375;
    margin-bottom: 1em;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    color: $heading-color;
}

.section-description {
    @extend %text-lead;
}

%text-small {
    font-size: .875em;
    line-height: 1.285714285714286;
}

.text-small {
    @extend %text-small;
}

%text-x-small {
    font-size: .75em;
    line-height: 1.333333333333333;
}

.text-x-small {
    @extend %text-small;
}

%text-mini {
    font-size: .75em;
    line-height: 1.333333333333333;
}

.text-mini {
    @extend %text-mini;
}


%text-size-1 {
    font-size: 24px;
    line-height: 1.111111111111111;
    letter-spacing: -.0277777777777778em;

    @include media-breakpoint-up(md) {
        font-size: 28px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 32px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 36px;
    }
}

.text-size-1 {
    @extend %text-size-1;
}

.h1 {
    @extend %text-size-1;
    @extend %heading;
}

h1 {
    @extend .h1;
}

%text-size-2 {
    font-size: 21px;
    line-height: 1.333333333333333;

    @include media-breakpoint-up(md) {
        font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 27px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 30px;
    }
}

.text-size-2 {
    @extend %text-size-2;
}

.h2 {
    @extend %text-size-2;
    @extend %heading;
}

h2 {
    @extend .h2;
}


%text-size-3-big {
    font-size: 18px;
    line-height: 1.333333333333333;

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 22px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 24px;
    }
}

.text-size-3-big {
    @extend %text-size-3-big;
}

%text-size-3 {
    font-size: 15px;
    line-height: 1.333333333333333;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 17px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 18px;
    }
}

.text-size-3 {
    @extend %text-size-3;
}

.h3 {
    @extend %text-size-3;
    @extend %heading;
}

h3 {
    @extend .h3;
}

%text-size-4 {
    font-size: 13px;
    line-height: 1.357142857142857;

    @include media-breakpoint-up(md) {
        font-size: 14px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 15px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 16px;
    }
}

.text-size-4 {
    @extend %text-size-4;
}

.h4 {
    @extend %text-size-4;
    @extend %heading;
}

h4 {
    @extend .h4;
    margin-bottom: 1rem;
}

%text-size-5 {
    font-size: 11px;
    line-height: 1.333333333333333;

    @include media-breakpoint-up(md) {
        font-size: 12px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 13px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 14px;
    }
}

.text-size-5 {
    @extend %text-size-5;
}

.h5 {
    @extend %text-size-5;
    @extend %heading;
}

h5 {
    @extend .h5;
    margin-bottom: 1rem;
}

%text-size-6 {
    font-size: .75em;
    line-height: $line-height-base;
}

.text-size-6 {
    @extend %text-size-6;
}

.h6 {
    @extend %text-size-6;
    @extend %heading;
}

h6 {
    @extend .h6;
    margin-bottom: 0;
}

strong {
    font-weight: 500;
}

a {
    transition: color.25s ease, background-color 0.3s ease;
}

$row-sizes: (60);

@each $row in $row-sizes {

    .row-#{$row} {
        @include custom-row($row*1px);
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .row#{$infix}-#{$row} {
                @include custom-row($row*1px);
            }
        }
    }
}
