.icons-list {
    @extend %clear-list;
    flex-direction: column;
    margin: 16px 0 30px;

    > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .ico-box {
            margin-right: 16px;
        }

        .text {
            font-size: .875em;
            line-height: 1.1;
            font-weight: 500;
            color: $dark;
        }
    }
}

.ico-box {
    @include size(40px);
    min-width: 40px;
    border-radius: 50%;
    border: 1px solid #9ee9b8;
    box-shadow: 0 0 0 6px rgba(158,233,184,0.3);
    padding: 10px;
    @extend %flex-center;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &-lg {
        @include size(76px);
        min-width: 76px;
        padding: 17px;
    }
}
