@import 'utils/functions';
@import 'utils/variables';

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css';
@import '../../node_modules/font-awesome-sass/assets/stylesheets/font-awesome';

@import 'utils/mixins';

@import '../../fonts/icomoon/style.css';
@import '../../fonts/maxicons/fonts/styles.css';


//utils
@import 'utils/mixins';
@import 'utils/fonts';

//components
@import 'components/default';

@import 'components/cards';
@import 'components/header';
@import 'components/icons-list';
@import 'components/forms';
@import 'components/footer';

//templates
@import 'templates/index';