.card {
    background: #fff;
    box-shadow: $shadow;

    .card-body {
        padding: 15px;

        @include media-breakpoint-up(md) {
            padding: 20px;
        }

        @include media-breakpoint-up(lg) {
            padding: 25px;
        }

        @include media-breakpoint-up(xl) {
            padding: 30px;
        }
    }
}

.card-blog {
    min-width: 299px;
    max-width: 299px;
    padding: 15px;
    border-radius: 6px;
    background-color: transparent;
    transition: all .25s ease;
    text-decoration: none;
    color: inherit;
    box-shadow: none;

    &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
        background-color: #fff;

        .title {
            text-decoration: underline;
        }
    }

    .image {
        border-radius: 3px;
        box-shadow: 0 13px 24px 0 rgba(0 ,0, 0, .2);
        height: 127px;
        margin-bottom: 30px;

        img {
            vertical-align: top;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .title {
        @extend .h5;
        margin-bottom: 16px;
    }

    .author {
        font-size: .875em;
        line-height: 1.071428571428571;
        margin-bottom: 22px;
        color: $black;
    }

    .excerpt {
        font-size: .875em;
        line-height: 1.428571428571429;
        color: rgba($color-text-alt, .7);
        max-height: 4.285714285714286em;
        overflow: hidden;
        letter-spacing: -.032em;
    }
}

.card-plan {
    border-radius: 5px;
    padding: 35px 0;

    .card-header {
        padding: 0 20px;
        background-color: transparent;
        border: none;

        @include media-breakpoint-up(md) {
            padding: 0 30px;
        }

        .title {
            margin-bottom: 26px;
        }

        .subtitle {
            margin-bottom: .35em;
            display: block;
        }

        .description {
            @extend %text-small;

            @include media-breakpoint-up(lg) {
                min-height: 138px;
            }
        }
    }

    .card-body {
        padding: 0 20px;
        background-color: transparent;
        border: none;

        @include media-breakpoint-up(md) {
            padding: 0 30px;
        }

        h4 {
            margin: 33px 0 21px;
        }
    }

    .card-divider {
        border-top: 1px solid $border-color;
        margin: 15px -20px 15px 0;

        @include media-breakpoint-up(md) {
            margin: 25px -30px 25px 0;
        }
    }

    .factor-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            font-weight: 500;
            @extend %text-x-small;
            color: $dark;
        }

        .factor {
            @include size(45px);
            min-width: 45px;
            border-radius: 50%;
            @extend %flex-center;
            @extend %text-size-3;
            @extend %heading;
            font-weight: 900;
            color: $primary;
            background-color: $dark;
            padding-bottom: 3px;
            margin-left: 15px;
        }
    }

    .check-list {
        @extend %clear-list;
        @extend %text-mini;

        > li {
            padding-left: 20px;
            margin-bottom: 16px;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                color: $primary;
                content: '✓';
            }
        }
    }

    .price {
        text-align: center;
        margin: 50px 0 25px;

        .old {
            @extend %text-size-3;
            font-family: $font-heading;
            display: inline-block;
            vertical-align: top;
            color: #545774;
            text-decoration: line-through; 
        }

        .new {
            @extend .h1;
            margin-bottom: 20px;
            line-height: 1;
            margin-top: -6px;
        }
    }
}