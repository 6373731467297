.index-landing {
    padding: 133px 0 0;
    position: relative;
    background-image: url(#{$path-img}bitmap.png), url(#{$path-img}bitmap2.png);
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    background-size: 620px auto, auto auto;

    @include media-breakpoint-down(md) {
        background-size: 450px auto, auto auto;
    }

    @include media-breakpoint-down(sm) {
        background: none;
    }

    .text-holder {
        margin: 0 0 45px;

        @include media-breakpoint-up(xl) {
            max-width: 475px;
        }
    }

    .slogan {
        margin-bottom: 32px;
    }

    .form-holder {
        background: $hero-form-bg;
        padding: 15px 20px 20px;
        margin: 0 0 20px;
        border-radius: 5px;

        @include media-breakpoint-down(sm) {
            padding: 15px 10px 20px;
        }

        .title {
            @extend %heading;
            font-size: 14px;
            margin: 0 0 28px;
            text-transform: uppercase;
        }

        .btn {
            border-radius: 0 3px 3px 0;
            font-size: 14px;
        }
    }

    .countdown-block {
        font-size: 12px;
        line-height: 1.083;
        border: 1px solid #ffE0c8;
        padding: 12px 35px;
        border-radius: 5px;
        color: $danger;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding: 12px 15px;
        }

        p {
            margin: 0;
        }

        .countdown {
            font-weight: 600;
        }
    }

    .image {

        @include media-breakpoint-up(xxl) {
            margin-right: -240px;
            margin-left: -50px;
        }

        img {
            max-width: 100%;
        }
    }

    & + * {
        z-index: 2;
        position: relative;
    }
}

.logos-title {
    @extend %text-small;
    line-height: 1.5;
}

.logos {
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    font-size: 0;

    @include media-breakpoint-up(lg) {
        margin: 0 -20px;
    }

    @include media-breakpoint-up(xl) {
        margin: 5px -36px;
    }

    > li {
        max-width: calc(50% - 30px);
        margin: 10px 15px;
        display: inline-block;
        vertical-align: middle;

        @include media-breakpoint-up(lg) {
            margin: 1px;
            max-width: calc(20% - 40px);
            margin: 15px 20px;
        }

        @include media-breakpoint-up(xl) {
            max-width: calc(20% - 72px);
            margin: 25px 36px;
        }

        img {
            max-width: 100%;
            vertical-align: middle;
        }
    }
}

.partners-section {
    text-align: center;
    padding: 28px 0 105px;

    @include media-breakpoint-down(xl) {
        padding: 82px 0 100px;
    }

    @include media-breakpoint-down(md) {
        padding: 30px 0 60px;
    }

    @include media-breakpoint-down(sm) {
        padding: 30px 0;
    }

    .logos-title {
        color: #71798c;
    }
}

.how-work-section {
    background: url(#{$path-img}how-work-bg.png) no-repeat 50% 50%;
    background-size: 1410px 100%;
    padding: 93px 0 160px;

    @include media-breakpoint-down(md) {
        padding: 70px 0;
    }

    .section-title {
        margin: 0 0 67px;
        letter-spacing: -.035em;

        @include media-breakpoint-down(md) {
            margin: 0 0 40px;
        }
    }

    .item {
        text-align: center;
        font-weight: 500;
        line-height: 1.25;
        color: $black;

        @include media-breakpoint-up(lg) {
            padding: 0 20px;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
            text-align: left;
            margin: 0 0 20px;
        }

        img {
            max-height: 137px;
            max-width: 100%;
        }
        
        .image-holder {
            height: 137px;
            margin: 0 0 31px;

             @include media-breakpoint-down(sm) {
                 height: auto;
                 width: 80px;
                 margin: 0 20px 0 0;
                 flex-shrink: 0;
             }
        }
    }
}

.plans-section {
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
        padding-top: 137px;
    }

    .section-title {
        margin-bottom: 1.333333em;
    }

    .plans-holder {
        position: relative;

        [class*="col"] {
            margin-bottom: 30px;
        }

        .container {
            position: relative;
            z-index: 2;
        }

        .bg {
            position: absolute;
            left: 0;
            top: 100px;
            width: calc(100% - 22px);
        }
    }
}

.text-columns-section {

    .section-head {

        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }
    }

    .ico-box {
        margin-bottom: 26px;
    }

    h3 {
        margin-bottom: 1em;
    }

    .blocks {

        [class*="col"] {
            margin-bottom: 50px;
        }
    }
}

.about-section {
    background: url(#{$path-img}about-bg.png) no-repeat 50% 0;
    background-size: 1608px 810px;
    padding: 168px 0 47px;

    @include media-breakpoint-down(lg) {
        background-size: 1608px 100%;
        padding: 130px 0 80px;
    }

    @include media-breakpoint-down(sm) {
        padding: 70px 0;
    }

    p {
        margin: 0 0 25px;
    }

    .subtitle {
        @extend %heading;
        @extend %text-small;
        margin: 0 0 25px;
        display: block;
        text-transform: uppercase;
    }

    .section-title {
        margin: 0 0 40px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 30px;
        }
    }

    .download-link {
        display: inline-flex;
        align-items: center;
        margin-top: 38px;
        font-size: 12px;
        color: #6d787e;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }

        &:hover {
            color: $primary;

            b {
                color: $primary;
            }
        }

        img {
            max-width: 55px;
            margin-right: 23px;
            flex-shrink: 0;

            @include media-breakpoint-down(md) {
                margin-right: 15px;
            }

            @include media-breakpoint-down(sm) {
                max-width: 40px;
            }
        }

        b {
            @extend %heading;
            display: block;
            font-size: 18px;
            transition: color .15s ease;

            @include media-breakpoint-down(md) {
                font-size: 16px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }
    }

    .image-block {
        margin: 15px -175px 0 50px;
        

        @include media-breakpoint-down(lg) {
           margin: 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 30px 0 0;
        }


        img {
            max-width: 100%;
        }
    }
}

.tweets-section {
    padding-bottom: 125px;

    @include media-breakpoint-down(md) {
        padding-bottom: 80px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 50px;
    }

    .section-title {
        margin: 0 0 57px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 25px;
        }
    }

    .item {
        border: 1px solid #eeeef4;
        border-radius: 5px;
        min-height: 215px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        margin: 0 0 15px;
        width: 100%;
        text-align: center;
        color: $primary;
        @extend %text-small;
    }
}

.section-blog-preview {
    position: relative;

    > * {
        position: relative;
        z-index: 2;
    }

    &:after {
        position: absolute;
        left: 0;
        top: -18px;
        right: 0;
        bottom:-60px;
        display: block;
        content: '';
        @include bgi('blog-preview-bg.png') {
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: 113px;
        padding-bottom: 102px;
    }

    .holder {
        margin: 0 -15px;

        @include media-breakpoint-up(xl) {
            margin: 0;
        }
    }

    .section-title {
        margin-bottom: 24px;
        letter-spacing: -.025em;
    }

    .text {
        font-size: .875em;
        line-height: 1.714285714285714;
        margin-bottom: 31px;
        color: $black;
        letter-spacing: -.025em;
    }

    .btns {
        display: flex;
        margin-right: -13px;
        margin-bottom: 20px;

        .btn-more {
            order: 1;
            color: #35ce59;

            &:hover {
                color: $white;
            }
        }

        .owl-next {
            order: 2;
        }

        .owl-prev {
            order: 3;
            display: none;
        }

        .owl-next,
        .owl-prev {

            .btn {
                color: #35ce59;

                &:hover {
                    color: $white;
                }
            }
        }

        .btn:not(.btn-circle) {

            @include media-breakpoint-down(sm) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
        }

        > * {
            margin-right: 13px;
        }
    }

    .owl-dots {
        display: none;
    }
}

.questions-section {
    padding: 170px 0 161px;

    @include media-breakpoint-down(md) {
        padding: 120px 0 80px;
    }

    @include media-breakpoint-down(sm) {
        padding: 80px 0 30px;
    }

    .title-section {
        margin: 0 0 13px;
        @extend %text-size-3-big;
    }

    .title {
        margin: 0 0 19px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 10px;
        }
    }

    .item {
        margin: 0 0 37px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 25px;
        }

        &:first-child {

            @include media-breakpoint-down(sm) {
                padding-top: 30px;
            }
        }
    }
}

.ready-started-section {
    padding: 0;

    .title {
        letter-spacing: normal;
    }

    .content-holder {
        border-top: 1px solid #eeeef4;
        padding: 57px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 40px 0;
        }
    }

    .btn-holder {
        margin: 0 -6px;

        @include media-breakpoint-down(md) {
            padding-top: 20px;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            margin: 0;
        }
    }

    .btn {
        min-width: 210px;
        margin: 0 6px;

        @include media-breakpoint-down(sm) {
            margin: 6px 0;
        }
    }

    .btn-pricing {
        background: #9eecb1;
        border-color: #9eecb1;
        color: #398e64;

        &:hover {
            background: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}