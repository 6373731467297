#footer {
    background: $footer-bg;
    padding: 36px 0 130px;
    font-size: 12px;
    color: $color-text-alt;

    @include media-breakpoint-down(md) {
        padding: 30px 0 60px;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $link-color;
        }
    }

    .row {

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .copyright {
        
        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
            font-size: 10px;
        }
    }

    .socials {
        font-size: 18px;
        margin: 0 0 1rem;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 40px;
        }

        .list-inline-item:not(:last-child) {
            margin-right: 15px;
        }

        a {
            opacity: 1;
            transition: opacity .35s;

            &:hover {
                opacity: .75;
            }
        }
    }

    .develop {

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }

        img {
            margin: 0 3px;
        }
    }

   .menu {
        margin: 0 -13px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
            justify-content: center;
        }

        > li {
            margin: 0 13px;
        }
   }
}
