@include font-face($graphik, 'graphik-regular');
@include font-face($graphik, 'graphik-medium', 500);
@include font-face($graphik, 'graphik-medium-italic', 500, italic);
@include font-face($graphik, 'graphik-semibold', 600);

@include font-face($fibra-one, 'fibra-one-bold', 700);
@include font-face($fibra-one, 'fibra-one-heavy', 900);

@font-face {
    font-family: 'icomoon';
    src:  url($path-fonts + 'icomoon/fonts/icomoon.eot');
    src:  url($path-fonts + 'icomoon/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url($path-fonts + 'icomoon/fonts/icomoon.ttf') format('truetype'),
    url($path-fonts + 'icomoon/fonts/icomoon.woff') format('woff'),
    url($path-fonts + 'icomoon/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'maxicons';
    src:  url($path-fonts + 'maxicons/fonts/fonts/maxicons.eot');
    src:  url($path-fonts + 'maxicons/fonts/fonts/maxicons.eot#iefix') format('embedded-opentype'),
    url($path-fonts + 'maxicons/fonts/fonts/maxicons.ttf') format('truetype'),
    url($path-fonts + 'maxicons/fonts/fonts/maxicons.woff') format('woff'),
    url($path-fonts + 'maxicons/fonts/fonts/maxicons.svg#maxicons') format('svg');
    font-weight: normal;
    font-style: normal;
}
