//Path

$path-fonts: '../fonts/';
$path-img: '../images/';

//Fonts
$arial: Arial;
$graphik: 'Graphik';
$fibra-one: 'Fibra One';

$font-primary: $graphik, sans-serif;
$font-secondary: $graphik, sans-serif;
$font-heading: $fibra-one;

$font-family-base: $font-primary;
$line-height-base: 1.5;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                rem(18px);
$font-size-sm:                rem(12px);

//Colors
$black: #000;
$grey: #1f1f32;
$white: #fff;
$green: #54d272;
$blue: #515572;

$dark: $black;

$color-text: $blue;
$color-text-alt: $grey;
$heading-color: $black;
$color-inverse: $white;
$danger: #ff6371;
$error: $danger;

$primary: $green;

$link-color: #24a953;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

//Grid
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1680px
);


//body
$body-bg: $white;
$body-color: $color-text;

$container-width: 1170px;

$spacer: rem(15px);

$container-offsets: (
    xs: 25px,
    sm: 35px,
    xl: 40px
);

$border-color: #eeeef4;

$shadow: 0 12px 24px 0 rgba(#000, .05);
$shadow-sm: 0 6px 12px 0 rgba(#000, .05);

//cards
$card-bg: #fff;
$card-border-width: 0;
$card-border-color: transparent;
$card-border-radius: 16px;
$card-spacer-x: 30px;
$card-spacer-y: 1em;
$card-margin-y: $grid-gutter-width;

$hero-form-bg: #9eecb1;
$footer-bg: #f5fbf7;
