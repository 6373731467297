.cta-form {
    display: flex;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    max-width: 630px;
    height: 40px;
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 43px;
    }

    .form-control {
        height: 100%;
        border-radius: 3px 0 0 3px;
        border-color: #fff;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: .4em 10px .3em;
        box-shadow: none;
        color: #354052;

        @include placeholder {
            color: $black;
            font-weight: 400;
        }

        @include media-breakpoint-up(md) {
            padding-left: 21px;
            padding-right: 21px;
        }

        &.error {
            border-color: $danger;
        }
    }

    label.error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 12px;
        color: $danger;
        margin: 2px 0 0;
    }

    .btn {
        padding: 8px;
        height: 100%;
        font-size: inherit;
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        @include media-breakpoint-up(md) {
            padding: 8px 20px;
        }

        @include media-breakpoint-up(lg) {
            padding: 10px 25px;
        }

        @include media-breakpoint-up(xl) {
            padding: 10px 34px;
        }
    }
}

.btn {
    transition: all .25s ease;
    border-radius: 24px;
    font-weight: 500;
    cursor: pointer;
    @include button-size(7px, 36px, 16px, 24px, 24px);

    @include media-breakpoint-up(md) {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.btn-primary {
    color: #fff;
}

.btn-circle {
    padding: 7px;
    min-width: 40px;
    box-shadow: $shadow;
    background-color: #fff;

    @include media-breakpoint-up(md) {
        padding: 8px;
        min-width: 42px;
    }

    @include media-breakpoint-up(lg) {
        padding: 10px;
        min-width: 46px;
    }

    @include media-breakpoint-up(xl) {
        padding: 11px;
        min-width: 48px;
    }
}

.btn-sm {
    @include button-size(9px, 23px, 14px, 20px, 20px);
}

.btn-black {
    @include button-variant(#000, #000, #333, #333);
}

.btn-outline-primary {
    border-color: #9eecb1;
    color: #398e64;
}